<template>
  <v-app>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
          <div class="d-flex">
            <v-img src="@/assets/logo.png" contain></v-img>
            <p>Koperasi Simpan Pinjam</p>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs grow>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab :href="`#tab-login`">
                    LOGIN
                  </v-tab>
                  <v-tab-item :value="'tab-login'" >
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col>
                            <p class="login-slogan display-2 text-center font-weight-medium my-10">KSP PUTRA DARMA CITRA</p>
                            <!-- <p class="login-slogan display-2 text-center font-weight-medium my-10">KSP SAMPLE</p> -->
                          </v-col>
                          <v-alert
                            dense
                            outlined
                            type="error"
                            :value="alert"
                          >
                            {{ message }}
                          </v-alert>
                          <v-form>
                            <v-col>
                              <v-text-field
                                  v-model="username"
                                  label="Username/Email Address"
                                  required
                              ></v-text-field>
                              <v-text-field
                                  v-model="password"
                                  type="password"
                                  label="Password"
                                  hint="At least 6 characters"
                                  required
                              ></v-text-field>

                            </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                  color="primary"
                                  @click="userLogin"
                              >
                                Login</v-btn>
                              
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© 2021 <a href="https://balisolutionbiz.com/" class="text-decoration-none">BaliSolutionBiz</a>, All rights reserved.</div>
              </v-footer>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import md5 from 'js-md5';
  export default {
    name: 'Login',
    data() {
      return {
        username: '',
        password: '',
        errors: null,
        valid: true,
        message: '',
        alert: false,
        // email: 'admin@flatlogic.com',
        // emailRules: [
        //   v => !!v || 'E-mail is required',
        //   v => /.+@.+/.test(v) || 'E-mail must be valid',
        // ],
        // createFullName: 'John Smith',
        // createEmail: 'john@flatlogic.com',
        // createPassword: '123456',
        // password: '123456',
        // passwordRules: [
        //   v => !!v || 'Password is required',
        //   v => v.length >= 6 || 'Min 6 characters'
        // ]
      }
    },  
    computed: {
      ...mapGetters({
        user : 'auth/user',
        prevUrl : 'prevUrl', // <= ini
      }),
    },
    methods: {
      ...mapActions({
        setAlert  : 'alert/set',
        setAuth   : 'auth/set',
      }),
      userLogin() {
        if (this.$refs.form.validate()) {
                let formData = {
                    'username' : this.username,
                    'password' : md5(this.password)
                }
                this.axios.post('/login', formData)
                .then((response) => {
                    const token = response.data.token  
                    localStorage.setItem('token', token) 

                    if(token){
                        this.axios.get('/user', {
                            headers: {
                                Authorization: 'Bearer ' + token,
                            }
                        })
                        .then(response => {
                            this.result = response.data;
                            console.log(this.result.data)
                            if(this.result.data){
                                this.setAuth(this.result.data)
                                localStorage.setItem('token', token) 
                                this.setAlert({
                                    status : true,
                                    color  : 'success',
                                    text  : 'Login success',
                                })
                                this.$router.push('/dashboard');
                            }else{
                                this.error_message=response.data.status;
                            }
                        })
                    }
                })
                .catch((error) => {
                  this.message = error.response.data.message
                  this.alert = true
                })
            }
        },
        close() {
            this.$emit('closed', false)
        }
    },
  }

</script>

<style src="./Login.scss" lang="scss"/>
